<template>
  <app-layout>
    <div class="flex flex-col lg:flex-row p-4">
      <div class="w-full lg:w-1/3 mb-4 lg:mb-0 lg:mr-2 lg:mt-4 lg:ml-2">
        <div class="flex flex-col space-y-6 text-center">
          <ul>
            <li v-for="menu in menus" :key="menu.id" class="text-left">
              <span>{{menu.name}}</span>
              <ul>
                <li v-for="video in menu.videos" :key="video.id" class="ml-4 cursor-pointer hover:text-purple-400">
                  <span @click="changeCurrent(video)">- {{video.name}}</span>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div class="w-full lg:w-2/3 mb-4 lg:mb-0 lg:mr-2 lg:mt-4 lg:ml-2">
        <div class="flex flex-col space-y-6 items-center">
          <content-card :title="current.name" v-if="current.youtube_video_id">
            <div class="align-center">
              <youtube :video-id="current.youtube_video_id" :width="width" />
            </div>
          </content-card>
        </div>
      </div>
    </div>
  </app-layout>
</template>
<script>
import _ from 'lodash';
import responsiveSize from '@/mixins/responsiveSize';
import TutorialService from '@/services/TutorialService';

export default {
  name: 'CoursesIndex',
  mixins: [
    responsiveSize,
  ],
  components: {
    AppLayout: () => import('@/components/layouts/AppLayout.vue'),
    ContentCard: () => import('@/components/molecules/ContentCard.vue'),
  },
  data() {
    return {
      width: 640,
      menus: [],
      current: {
        id: null,
        name: null,
        youtube_video_id: null,
      },
    }
  },
  created() {
    window.addEventListener('resize', _.debounce(this.resize, 200));
    this.resize();
  },
  destroyed() {
    window.removeEventListener('resize', _.debounce(this.resize, 200));
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      const courses = await TutorialService.get();
      this.menus = courses.sort((a, b) => a.order - b.order).map((course) => ({
        id: course.id,
        name: course.name,
        videos: course.submenuTutorials.map((subCourse) => ({
          id: subCourse.id,
          name: subCourse.name,
          youtube_video_id: subCourse.url.replace("https://youtu.be/", ""),
        })),
      }));
    },
    resize() {
      const { outerWidth } = window;
      this.width = outerWidth / 1.75;
    },
    changeCurrent(video) {
      this.current = video;
    },
  },
}
</script>
