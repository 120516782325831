import HttpProxy from '@/services/HttpProxy';

export default class TutorialService {
    static async get(parameters) {
        const response = await HttpProxy.submit({
            method: 'get', url: 'Tutorials', parameters,
        });
        return response?.data ?? null;
    }
}
